import React from "react";
import { Button } from "../../common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeholder from "../../../assets/heroplaceholder.png";
import {
  SHeroWrapper,
  SHeroContainer,
  ImageContainer,
  ImageWrapper,
  
  Frame1,
  Frame2,
  Frame3,
  TextContentWrap,
  TextContentHead,
  TextContent,
} from "./styles";
const ServiceHeroSection = ({widthCheck}) => {
  return (
    <SHeroWrapper>
      <SHeroContainer>
        <ImageContainer>
          <ImageWrapper resize={widthCheck}>
            <Frame1 widthset={widthCheck} />
            <Frame2 widthset={widthCheck} />
            <Frame3 widthset={widthCheck} />
            <LazyLoadImage
              effect="blur"
              className="eimage"
              placeholderSrc={placeholder}
              src="https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Services/Group_488_EG2Rdb4M6W.png"
              alt="e-commerce"
            />
          </ImageWrapper>
        </ImageContainer>

        <TextContentWrap>
          <TextContentHead>E‑commerce Development</TextContentHead>
          <TextContent>
            Expand your online business and sell more with modern Shopify or
            custom e‑commerce solutions.
          </TextContent>
          <Button
            btnClass="Heroservices"
            Btext={"BOOK A CALL"}
            link="/contact"
            widthChange={true}
          />
        </TextContentWrap>
      </SHeroContainer>
    </SHeroWrapper>
  );
};

export { ServiceHeroSection };
