import React from "react";
import { Helmet } from "react-helmet";

const ResourcesMetaRoute = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="resources"
        content="Alero is a Product Design Agency
"
      />

      <title>Alero | Resources</title>
    </Helmet>
  </div>
);

export default ResourcesMetaRoute;
