import React, { useEffect, useState } from "react";
import ContactMetaRoute from "../meta/Contact";

import { MobileMenu, Navigation, FooterSection } from "../components/common";
import { ContactSection, Success } from "../components/Contact";
import { ContactData } from "../data/ContactData";
import { FooterServices } from "../data/footerData";

import Loader from "../components/loader";

const Contact = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
    const [navSolid, setNavSolid] = useState(false);
  let scrolle;
  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    let scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);

     if (document.readyState === "loading") {
       setwindowloading(true);
     } else if (document.readyState === "interactive") {
       setwindowloading(true);
     } else if (document.readyState === "complete") {
       setwindowloading(false);
     }
  }, [windowloading]);

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  //success form submission
  function submitContactForm() {
    setSubmitSuccess(true);
  }

  const loadedHandler = () => {
    if (document.readyState === "loading") {
      setwindowloading(true);
    } else if (document.readyState === "interactive") {
      setwindowloading(true);
    } else if (document.readyState === "complete") {
      setwindowloading(false);
    }
  };

    const navSolidChange = () => {
      if (window.scrollY >= 150) {
        setNavSolid(true);
      } else {
        setNavSolid(false);
      }
    };
  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
  window.addEventListener("scroll", navSolidChange);
  //check the submission of the Forms

  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <ContactMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FEF0D4"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          {!submitSuccess ? (
            <ContactSection
              widthchanges={scrollWidth}
              ContactData={ContactData}
              submitForm={submitContactForm}
            />
          ) : (
            <Success />
          )}

          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Contact;
