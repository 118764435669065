export const FooterServices = [
  {
    title: "Website & Mobile Development",
    path: '/services'
  },
  
  {
    title: "Online Store",
    path:'/ecommerce'
  },

  {
    title: "Logo & Brand Design",
    path:'/branding'
  },
 
];
