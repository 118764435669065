import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
width: 100%;
height:100%;
`;

export const Hero = styled.div`
  height: 411px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;

   
  }
  
  @media all and (min-width: 280px){
    height: 200px;

  }

  @media all and (min-width: 446px){
    height:250px;
  }
  @media (min-width: 569px) and (max-width: 1365px) {
    height: 400px;
    -o-object-fit: contain;
    object-fit: contain;
    video {
      -o-object-fit: contain;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  @media all and (min-width: 1366px) {
    height: 600px;
    width: 1090px;
    margin: auto;
    video {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const IntroWrapper = styled.div`
width: 88%;
margin:auto;
position:relative;
margin-bottom: 80px;
@media all and (min-width:563px){
    width: 495.44px;
}
@media all and (min-width: 768px){
    width: 600px;
}

@media all and (min-width:1366px){
    width: 1090px;
}
img {
    position: absolute;
    z-index: -2;
    top:50px;
    width: 96px;
    height:78px;
}
`;

export const IntroTitle = styled.h1`
  width: 100%;
  font-size: 21.3642px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #264d2d;
  margin-bottom: 50px;
  margin-top: 20px;

  @media all and (min-width: 768px) {
    font-size: 23px;
  }
  @media all and (min-width: 1366px) {
    width: 500px;
    justify-content: left;
    font-size: 16.3642px;
    line-height: 29px;
  }
`;
export const IntroTextWrapper = styled.div`
@media all and (min-width:1366px){
    display:flex;
    justify-content: space-between;
}
`;
export const IntroText = styled.p`
  font-size: 18.0233px;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 30px;
  @media all and (min-width: 768px) {
    font-size: 19px;
    line-height: 28px;
  }
  @media all and (min-width: 1366px) {
    width: 500px;
  }
`;

export const IntroSubTitle = styled.h5`
  font-style: italic;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  width: 100%;
  text-align: justify;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media all and (min-width: 768px) {
    font-size: 27px;
  }

  @media all and (min-width: 1366px) {
    font-size: 37px;
    line-height: 35px;
    width: 700px;
    text-align: justify;
  }
`; 

export const ContentDetailWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media all and (min-width: 563px) {
    width: 495.44px;
    margin: auto;
  }
  @media all and (min-width: 768px) {
    width: 600px;
  }
  @media all and (min-width:1366px){
      width:1090px;
  }
`;

export const ContentDetailContainer = styled.div`
  @media all and (min-width: 1366px) {
    position: relative;

    height: 514px;
    margin-bottom: 70px;
  }
`;

export const ContentImages = styled.div`
  height: 314px;
  background: ${({ bg }) => bg};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media all and (min-width: 1366px) {
    width: 554px;
    height: 514px;
    position: absolute;
    right: 0;
    z-index: -2;

    &.imagesleft {
      left: 0;
      z-index: -2;
    }
  }

  .images {
    width: 185.54px;
    height: 148.4px;
  }
  .icon {
    position: absolute;
    width: 77.21px;
    height: 90.87px;
    top: 20px;
    right: 20px;
  }
  .icon2 {
    position: absolute;
    width: 77.21px;
    height: 90.87px;
    bottom: 20px;
    left: 20px;
  }
`;

export const ContentDetailTextWrapper = styled.div`
  height: 312.34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 7.08621px 7.08621px 18.5332px rgba(0, 0, 0, 0.129);
  border-radius: 2.72546px;
  margin-bottom: 60px;

  @media all and (min-width: 1366px) {
    width: 554px;
    height: 373px;
    background: #fbfbfb;
    position: relative;
    box-shadow: -15px 10px 34px rgba(0, 0, 0, 0.129);
    top: 50px;

    &.Textleft {
      background: #fbfbfb;
      left: 534px;
      box-shadow: -15px 10px 34px rgba(0, 0, 0, 0.129);
    }
  }
`;

export const ContentDetailTitle = styled.h1`
  font-weight: 300;
  font-size: 35.4478px;
  line-height: 48px;
  width: 294px;
  margin: auto;
  margin-bottom: 0;

  @media all and (min-width: 768px) { 
    width: 497px;
  }
`;

export const ContentDetailText = styled.p`
  font-weight: normal;
  font-size: 18.0233px;
  line-height: 25px;
  width: 294px;
  margin: auto;
  color: #282828;
  margin-top: 20px;

  @media all and (min-width: 768px){
      font-size: 19px;
      line-height: 28px;
      width: 497px;
  }
`;



export const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 70px;
  @media all and (min-width: 1366px) {
    width: 1090px;
    margin: auto;
    margin-bottom: 70px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media all and (min-width: 1366px) {
    flex-direction: row;
  }
`;
export const Frame = styled.div`
  background: #f2c335;
  width: 75.18px;
  height: 75.18px;
  transform: rotate(-10.29deg);
  margin-bottom: 20px;

  @media (min-width: 768px) and (max-width: 1365px) {
    margin-bottom: 50px;
  }
`;

export const ButtonContentText = styled.h5`
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 34px;
  text-align: center;
  width: 80%;

  @media all and (min-width: 360px) {
    width: 288px;
  }
  @media (min-width: 768px) and (max-width: 1365px) {
    margin-bottom: 50px;
    width: 600px;
  }
  @media all and (min-width: 1366px) {
    margin-bottom: 0px;

    width: 700px;
  }
`;

export const TeamDetailWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media all and (min-width: 563px) {
    width: 495.44px;
    margin: auto;
  }

  @media all and (min-width: 768px) {
    width: 600px;
  }

  @media all and (min-width: 1366px){
      width: 1090px;
      position:relative;
  }
`;

export const TeamTitle = styled.h1`
  font-size: 21px;
  line-height: 29px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: #264d2d;
  margin-bottom: 20px;
  @media all and (min-width: 1366px) {
    justify-content: left;
    font-size: 16.3642px;
    line-height: 29px;
    margin-bottom: 5px;
  }
`;

export const TeamPhotoWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  @media all and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media all and (min-width: 1366px) {
    height: 620px;
    display:block;
    position: relative;
  }
`;

export const PhotoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  height: 100%;
  img {
    width: 246.67px;
    height: 246.67px;
    box-shadow: 24.6673px 24.6673px 64.6047px rgba(0, 0, 0, 0.129);
    border-radius: 117.463px;
  }

  @media all and (min-width: 768px) {
    :last-child:nth-child(2n - 1) {
      grid-column: span 2;
    }
  }

  @media all and (min-width: 1366px){
      display: block;
      margin-top:20px;
      position:absolute;
      left: ${({left})=>(left)};
      top:${({top})=>(top)};
  }
`;

export const PhotoText = styled.p`
  margin-top: 27px;
  font-size: 21.1434px;

  @media all and (min-width: 1366px) {
    width: 246.67px;
    text-align:center;
  }
`;

export const BackIcons = styled.div`
display: none;

@media all and (min-width: 1366px){
    display:block;
    position: absolute;
    top: 0;
    
}
`;

export const Frame1 = styled.img`
  position: absolute;
  left: 350px;
  top: 130px;
  width: 44.12px;
  height: 99px;
`;

export const Frame2 = styled.img`
  position: absolute;
  left: 690px;
  top: 380px;
  width: 44.12px;
  height: 99px;
`;

export const Frame3 = styled.img`
  position: absolute;
  left: 1070px;
  top: 130px;
  width: 44.12px;
  height: 99px;
`;
export const ContactDetailWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 80px;

  @media all and (min-width: 1366px) {
    width: 1090px;
    margin: auto;
    margin-bottom: 80px;
  }
`;

export const ContactDetailTitle = styled.h5`
  font-weight: 600;
  font-size: 30px;
  line-height: 47px;
  display: flex;
  justify-content: center;
  @media all and (min-width: 1366px) {
    justify-content:left;
  }
`;

export const ContactDetailWrap = styled.div`
@media all and (min-width: 1366px){
    display: flex;
   justify-content: space-between;
   width: 45%;
}
`;
export const ContactDetailContainer = styled.div`
  margin-top: 30px;
@media all and (min-height: 1366px){
    
}
`;

export const ContactDetailLocation = styled.h5`
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #3a7a44;
  margin-bottom: 10px;
  @media all and (min-width: 1366px) {
    justify-content: left;
  }
`;

export const ContactDetailSubTitleWrap = styled.div`
@media all and (min-width: 1366px){
    height:160px;
}
`;
export const ContactDetailSubTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: #989898;
  @media all and (min-width: 1366px) {
    
    text-align: left;
  }
`;

export const ContactPhone = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 10px;
  color: #989898;
  @media all and (min-width: 1366px) {
    text-align: left;
  }
`;

export const ContactEmail = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;

  color: #989898;
  @media all and (min-width: 1366px) {
    
    text-align: left;
    
  }
`;