import React, { useRef } from "react";
import {
  SideMenuLink,
  SideIcon,
  SideMenuItem,
  SideMenuNItem,
  SideMenuWrap,
  DropdownLink,
  SideMenuDropItem,
  Wrapper,
  SideMenuContainer,
  SideMenuDropSubText,
  WrapContainer,
} from "./subStyles";

import {useDetectOutsideClick} from '../../Hooks/DetectOutisdeClicks';
const SubMenu = ({ item, textColor, screenwidth}) => {
  const dropRef = useRef(null);
  const [subnav, setSubnav] = useDetectOutsideClick(dropRef, false);
  
  const showSubnav = () => {
    //setSubnav(!subnav);
    setSubnav((prevState)=> !prevState);
  };

  return (
    <>
      <SideMenuContainer ref={dropRef}>
        {item.Checkpath === true ? (
          <SideMenuLink to={item.path} onClick={item.subNav && showSubnav}>
            <SideMenuWrap>
              <SideMenuItem textcolor={textColor} to={item.path}>
                {item.title}
              </SideMenuItem>
            </SideMenuWrap>

            <SideIcon textcolor={textColor}>
              {item.subNav ? item.icon : item.subNav ? item.icon : null}
            </SideIcon>
          </SideMenuLink>
        ) : (
          <SideMenuLink  onClick={item.subNav && showSubnav}>
            <SideMenuWrap>
              <SideMenuNItem textcolor={textColor} >
                {item.title}
              </SideMenuNItem>
            </SideMenuWrap>

            <SideIcon textcolor={textColor}>
              {item.subNav ? item.icon : item.subNav ? item.icon : null}
            </SideIcon>
          </SideMenuLink>
        )}

        {subnav && (
          <Wrapper screenwidth={screenwidth} checkdropdown={item.title}>
            <WrapContainer checkdropdown={item.title}>
              {item.subNav.map((item, index) => {
                return (
                  <DropdownLink to={item.path} key={index}>
                    <div>
                      <SideMenuDropItem>{item.title}</SideMenuDropItem>
                      <SideMenuDropSubText>{item.subtitle}</SideMenuDropSubText>
                    </div>
                  </DropdownLink>
                );
              })}
            </WrapContainer>
          </Wrapper>
        )}
      </SideMenuContainer>
    </>
  );
};

export default SubMenu;
