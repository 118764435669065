import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div``;

export const TextWrapper = styled.div`
width:90%;
margin:auto;
margin-bottom: 33px;
@media all and (min-width: 1366px){
  display:flex;
  align-items:center;
width: 760px;
}

`;
export const TextContentContainer = styled.div`
position:relative;
`;
export const TextContent = styled.p`
font-weight:500;
font-size:24px;
line-height:30px;
text-align:center;
margin-bottom:16px;

@media all and (min-width: 1366px){
  text-align: left;
  width: 400px;
  font-weight: 600;
}
`;

export const SubText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  font-style: italic;
  margin-bottom: 20px;
  @media all and (min-width: 1366px) {
    text-align: left;
    justify-content: left;
    font-style: italic;
  }
`;

export const ImageWrapper = styled.div`
text-align:center;
  width: 100%;
  margin:auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    width: 370px;
    height:270px;
    margin:auto;
  }

  @media all and (min-width: 1366px){
    flex-direction: row;
    width: 720px;
  }
`;