import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const ContentButtonWrap = styled.div`
  background: radial-gradient(135% 135% at 5.8% 0%, #a5c77d 0%, #3a7a44 100%);
  width: 265px;
  width: ${({ widthchange }) => (widthchange ? "285px" : "265px")};
  margin: auto;
  padding: 16px 57px;
  border-radius: 3rem;
  &:hover {
    filter: drop-shadow(13px 13px 34px rgba(0, 0, 0, 0.2));
   

    border-image-source: linear-gradient(
      180deg,
      #fde1a9 0%,
      #fdebc7 32.81%,
      #ffffff 100%
    );
  }
  @media all and (min-width: 1366px) {
    margin: ${({ btnClass }) => {
      if (btnClass === "Heroservices") {
        return "0px";
      }
    }};
  }
`;

export const ButtonText = styled(Link)`
  text-decoration: none;
  font-size: 19.3902px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width:100%;
  display:flex;
  justify-content:center;
  `;
