
export const TeamData = [
  {
    name: "Stephanie Kirathe",
    profile:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/About/Steph_oM-8d_sMN.png",
    left: "23px",
    top: "0px",
  },
  {
    name: "Joshua Kimbareeba",
    profile: 'https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/About/Joshua_2__7QMtdZgiZ.png',
    left: "393px",
    top: "240px",
  },
  {
    name: "Evans Kimathi",
    profile:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/About/evans_a9c2-q33a.png",
    left: "783px",
    top: "0px",
  },
];
