import React, { useState, useEffect } from "react";
import ServicesMetaRoute from "../meta/Services";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  Lists,
} from "../components/common";
import {
  ServiceHeroSection,
  ServiceSolution,
} from "../components/ServicesPage";
import Loader from "../components/loader";
import { FooterServices } from "../data/footerData";
import {
  SolutionData,
  TabsData,
  ServicesDetailData,
} from "../data/ServiceSolution";
const Services = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
  const [navSolid, setNavSolid] = useState(false);

  useEffect(() => {
    let totalWidth = window.screen.width;
    setScreenWidth(totalWidth);

     if (document.readyState === "loading") {
       setwindowloading(true);
     } else if (document.readyState === "interactive") {
       setwindowloading(true);
     } else if (document.readyState === "complete") {
       setwindowloading(false);
     }
  }, [windowloading]);

  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  const changeScreenWidth = () => {
    let totalScreenWidth = window.screen.width;
    setScreenWidth(totalScreenWidth);
  };

  const loadedHandler = () => {
     if (document.readyState === "loading") {
       setwindowloading(true);
     } else if (document.readyState === "interactive") {
       setwindowloading(true);
     } else if (document.readyState === "complete") {
       setwindowloading(false);
     }
  };

   const navSolidChange = () => {
     if (window.scrollY >= 400) {
       setNavSolid(true);
     } else {
       setNavSolid(false);
     }
   };
  window.addEventListener("resize", changeScreenWidth);
  document.addEventListener("readystatechange", loadedHandler);
  window.addEventListener("scroll", navSolidChange);
  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <ServicesMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FDE1A9"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <ServiceHeroSection ScreenWidth={screenWidth} />
          <ServiceSolution SolutionData={SolutionData} />
          <Lists
            TabStyle="services"
            TabsData={TabsData}
            serviceData={ServicesDetailData}
          />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Services;
