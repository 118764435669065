import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  height: 100%;
  border-top: 1px solid #ababab;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  @media all and (min-width: 1270px) {
    display: grid;
    grid-template-columns: 0.8fr 0.3fr 0.2fr 0.2fr 0.2fr;
  }
`;

export const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media all and (min-width: 1270px) {
    width: 50%;
    position: relative;
  }
`;

export const LogoSection = styled.img`
  margin-top: 30px;

  @media all and (min-width: 1270px) {
    width: 202.36px;
    height: 63.56px;

    display: flex;
    flex-direction: column;
    margin-left: -30px;
    justify-content: left;
    align-items: center;
  }
`;

export const CompanyHighlight = styled.div`
  font-size: 17px;
  color: #022211;
  text-align: center;
  margin-top: 19px;
  font-weight: 600;

  @media all and (min-width: 1270px) {
    text-align: left;
  }
`;

export const ContactDetails = styled.div`
  margin-top: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #022211;
  @media all and (min-width: 1270px) {
    text-align: left;
  }
`;

export const Mobile = styled.p`
  margin-bottom: 3px;
`;

export const EmailDetails = styled.p``;

export const SocialMediaPlatforms = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;

  color: #264d2d;

  a {
    text-decoration: none;
    color: #264d2d;
  }

  @media all and (min-width: 413px) {
    width: 210px;
  }
  @media all and (min-width: 1270px) {
    width: 200px;
  }
`;

export const ServicesDetails = styled.div`
  text-align: center;
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media all and (min-width: 1270px) {
    text-align: left;
    justify-content: center;
  }
`;

export const ServiceTitle = styled.h5`
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 14px;
  width: 300px;
`;
export const ServiceContent = styled(Link)`
  text-decoration: none;
  display: block;
  width: 300px;
  font-size: 17px;
  line-height: 30px;
  color: #022211;
  margin-bottom: 2px;
`;

export const FooterDetails = styled.div`
  text-align: center;
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media all and (min-width: 1270px) {
    &#discover {
      display: none;
    }
    &#support {
      text-align: left;
      justify-content: center;
    }
    &#company {
      text-align: left;
      justify-content: center;
    }
  }
`;

export const FooterDetailTitle = styled.h5`
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 14px;
  width: 300px;
  @media all and (min-width: 1270px) {
    width: 200px;
  }
`;

export const FooterContent = styled(Link)`
text-decoration:none;
  font-size: 17px;
  line-height: 30px;
  color: #022211;
  margin-bottom: 2px;
  width: 300px;
  @media all and (min-width: 1270px) {
    width: 200px;
  }
`;

export const CopyRightSection = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ababab;
  text-align: center;
  font-weight: 600;
  line-height: 14px;
`;
