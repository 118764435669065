/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import HomeMetaRoute from "../meta/Home";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  SupportSection,
} from "../components/common";
import {
  HeroSection,
  ProcessSection,
  RecentWork,
  ServicesSection,
  TechSection,
 // TestimonySection,
} from "../components/Index";

import Loader from "../components/loader";
import { WorkData } from "../data/RecentWorkData";
import { ServicesData } from "../data/ServicesData";
import { ImageData, InfoData } from "../data/TechData";
import { ProcessData } from "../data/ProcessData";
//import { Testimonies } from "../data/TestimoniesData";
import { FooterServices } from "../data/footerData";
const Home = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
   const [navSolid, setNavSolid] = useState(false);
  let scrolle;

  useEffect(() => {
    scrolle = window.screen.width;

    let total = scrolle;
    setScrollWidth(total);
 
    if(document.readyState === 'loading'){
      setwindowloading(true);
    } else if (document.readyState === 'interactive'){
      setwindowloading(true);
    }
    else if(document.readyState === 'complete'){
       setwindowloading(false)
    }
  }, []);

  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  const loadedHandler = () => {
   
   if (document.readyState === "loading") {
     setwindowloading(true);
   } else if (document.readyState === "interactive") {
     setwindowloading(true);
   } else if (document.readyState === "complete") {
     setwindowloading(false);
   }
  };

   const navSolidChange = () => {
     if (window.scrollY >= 400) {
       setNavSolid(true);
     } else {
       setNavSolid(false);
     }
   };
  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
   window.addEventListener("scroll", navSolidChange);
  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
           <HomeMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            displayfalse={true}
            menuColor="#FDE1A9"
            screenWidth={scrollWidth}
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <HeroSection />
          <RecentWork recentData={WorkData} />
          <ServicesSection serviceData={ServicesData} />
          <TechSection
            LogoData={ImageData}
            leftCalc={scrollWidth}
            InfoData={InfoData}
          />
          <ProcessSection leftCalc={scrollWidth} ProcessData={ProcessData} />
          
         
        <SupportSection />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Home;

 // <TestimonySection
          //   title={true}
          //   subText={"What our clients say..."}
          //   TestimonyData={Testimonies}
          // />