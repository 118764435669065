import React from "react";
import {
  Wrapper,
  Container,
  ServiceContainer,
  ServiceDetailWrapper,
  ServiceDetailTitle,
  ServiceDetailContainer,
  ServiceBrandDetail,
  ServiceTitle,
} from "./serviceStyles";
import {
  ServiceTab,
  SubServiceDetail,
  ServiceDetail,
  SiteExamples,
  ServiceButton,
  BrandExamples
} from "../../common";
const Lists = ({
  TabStyle,
  TabsData,
  serviceData,
  listStyle,
  brandExamples,
  BrandingDetail,
}) => {
  return (
    <Wrapper>
      <Container>
        <ServiceTab
          TabStyle={TabStyle}
          listStyle={listStyle}
          TabData={TabsData}
        />

        {serviceData.map((data, index) => {
          return (
            <ServiceContainer id={data.Title}>
              <ServiceDetail checkId={index} serviceData={data} />

              <SubServiceDetail
                type={data.serviceType}
                title={data.serviceDetailTitle}
                serviceDetails={data.serviceDetail}
              />

              {data.BottomButton && (
                <ServiceButton
                  Head={data.BottomButtonHead}
                  BtnText={data.BottomButtonText}
                  link="/contact"
                />
              )}

              {data.brandService && (
                <ServiceDetailWrapper>
                  <ServiceDetailTitle>
                    {data.brandServiceTitle}
                  </ServiceDetailTitle>
                  <ServiceDetailContainer>
                    {data.brandServiceDetails.map((data, index) => {
                      return (
                        <ServiceBrandDetail>
                          <img
                            className={`brand ${data.class}`}
                            src={data.src}
                            alt={data.title}
                          />
                          <ServiceTitle className={`brandsT  ${data.Tclass}`}>
                            {data.title}
                          </ServiceTitle>
                        </ServiceBrandDetail>
                      );
                    })}
                  </ServiceDetailContainer>
                </ServiceDetailWrapper>
              )}
            </ServiceContainer>
          );
        })}
        {brandExamples ? (
          <BrandExamples BrandingDetail={BrandingDetail} />
        ) : (
          <SiteExamples />
        )}
      </Container>
    </Wrapper>
  );
};

export { Lists };
