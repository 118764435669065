import { Link } from "react-router-dom";
import styled from "styled-components";

export const SuccessWrapper = styled.section`
  width: 100%;
  height: 100vh;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
`;

export const SuccessContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const SuccessImg = styled.img`
  margin-bottom: 7px;
`;
export const SuccessTextmain = styled.h1`
  top: 10px;
  font-weight: bold;
  color: #3a7a44;
  font-size: 40px;
  margin-bottom: 30px;
  width: 80%;
`;
export const SuccessText = styled.h1`
  
  font-weight: normal;
  color: black;

  margin-bottom: 30px;
  width: 80%;
`;

export const SuccessBtn = styled(Link)`
  border: 2px solid #264c2d;
  color: #264c2d;
  padding: 20px;
  text-decoration: none;
  padding: 16px 57px;

  border-radius: 3rem;
`;
