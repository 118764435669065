
 export const pressData = [
   {
     pressCover:
       "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/resources/A4_-_1-1_Pb2WaV1uj2.png",
     subtitle: "Press & Corporate",
     title: "Custom Website 2020-2021",
     path:
       "https://drive.google.com/file/d/1IPiE8XctsaVy_7BBQpyj001cfUfz1hzW/view",
     buttontext: "DOWNLOAD",
   },
   {
     pressCover:
       "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/resources/A4_-_1_2yjq2QEUMD.png",
     subtitle: "Press & Corporate",
     title: "Brand Development 2021",
     buttontext: "COMING SOON",
     soonbtnCheck: true,
   },
   {
     pressCover:
       "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/resources/A4_-_5_R86D3zzGQv.png",
     subtitle: "Press & Corporate",
     title: "Company Profile",
     buttontext: "COMING SOON",
     soonbtnCheck: true,
   },
 ];

export const OpenSourceData = [
  {
    title: "React boilerplate",
    subtext: "Alero’s React.js boilerplate for website projects",
  },
  {
    title: "Vue.js CDN boilerplate",
    subtext: "Alero’s Vue.js boilerplate for landingpages &  small projects.",
  },
  {
    title: "MEVN stack boilerplate",
    subtext: "Alero's mongo+vue+node+express boilerplate",
  },
  {
    title: "React Native broilerplate",
    subtext: "Alero’s React.js broilerplate Expo + Redux Pre-setup Template",
  },
];
