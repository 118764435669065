import styled from "styled-components";

export const SHeroWrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  background: linear-gradient(180deg, #fef0d4 0%, #fef5e3 32.81%, #ffffff 100%);
  @media all and (min-width:1366px){
    background:transparent;
  }
`;

export const SHeroContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 33px;
  @media all and (min-width: 1366px) {
  
 
  }
`;

export const TextContentWrap = styled.div`
  width: 100%;
  
`;

export const TextContentHead = styled.div`
  font-weight: 600;
  font-size: 44px;
  line-height: 54px;
  text-align: center;
  color: #264d2d;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  @media all and (min-width: 1366px) {
    padding-top: 140px;
    text-align: left;
    width: 400px;
    margin-left: ${({ widthchange }) => `${widthchange * 0.1}`}px;
    margin-right: auto;
  }
  @media all and (min-width: 1891px) {
    margin-left: ${({ widthchange }) => `${widthchange * 0.14}px`};
  }
  @media all and (min-width: 1366px) {
    color: #151515;
  }
`;

export const TextContent = styled.p`
  line-height: 20px;
  text-align: center;
  font-size: 15px;
  color: #151515;
  font-weight: 400;
  width: 90%;
  margin: auto;
  margin-bottom: 23px;

  @media all and (min-width: 1366px) {
    text-align: left;
    width: 300px;
    margin-left: ${({ widthchange }) => `${widthchange * 0.1}`}px;
    margin-right: auto;
  }
  @media all and (min-width: 1891px) {
    margin-left: ${({ widthchange }) => `${widthchange * 0.14}px`};
  }
`;
