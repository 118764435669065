import React from "react";
import AleroLogo from "../../../assets/Icons/Logo.svg";
import {
  HeroWrapper,
  HeroContainer,
  LogoSection,
  Logo,
  CompanyName,
  ContentSection,
  Content,
  ContentSubText,
  ButtonWrapper,
  Button,
  BgWrap,
  BgImage,
  HeroWrapContent,
} from "./HeroStyles";
const HeroSection = () => {
  return (
    <HeroWrapper>
      <HeroContainer>
        <BgWrap>
          <BgImage />
        </BgWrap>
        <HeroWrapContent>
          <LogoSection>
            <Logo src={AleroLogo} alt="Alero Logo"/>
            <CompanyName>ALERO</CompanyName>
          </LogoSection>
          <ContentSection>
            <Content>Digital Branding & Software development experts</Content>
            <ContentSubText>Get your free quotation.</ContentSubText>
          </ContentSection>
          <ButtonWrapper>
            <Button to="/contact">Get An Estimate</Button>
          </ButtonWrapper>
        </HeroWrapContent>
      </HeroContainer>
    </HeroWrapper>
  );
};

export { HeroSection };
