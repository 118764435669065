import React, { useState } from "react";
import ComingSoon from "../components/ComingSoon";
import { FooterServices } from "../data/footerData";
import { MobileMenu, Navigation, FooterSection } from "../components/common";
const PageComingSoon = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };
  return (
    <>
      <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
      <Navigation
        menuColor="#FEF0D4"
        toggle={toggle}
        mobileActive={openMobileMenu}
      />
      <ComingSoon />
      <FooterSection FServices={FooterServices} />
    </>
  );
};

export default PageComingSoon;
