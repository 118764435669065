import React from "react";
import { Helmet } from "react-helmet";

const ServicesMetaRoute = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="services"
        content="Alero is a Product Design Agency
"
      />

      <title>Alero | Services</title>
    </Helmet>
  </div>
);

export default ServicesMetaRoute;
