
import React, { useState } from "react";
import { ContactHeroSection } from "../../Contact";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  Wrapper,
  MainWrapper,
  Container,
  HeadWrap,
  HeadingText,
  HeadingSubText,
  ServiceWrapper,
  Content,
  EmptySelection,
  FullSelection,
  ContentText,
  SelectionCheck,
  Image,
  FormWrapper,
  Form,
  FormP,
  FormLabel,
  FormInput,
  FormButton,
  FormTextArea,
  FormError,
  FormContent,
  FormContentWrap,
  ContactDetails,
  CallDetails,
  EmailDetails,
} from "./style";
//react loader
import Loader from "react-loader-spinner";
import FormDetails from "../Form/form";
import validateInfo from "../Form/validation";
const ContactSection = ({ ContactData, submitForm, widthchanges }) => {
  const [activeStatus, setActiveStatus] = useState({ id: 0, active: true });

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    submitError,
    isSubmitting,
    handleServiceSelection,
  } = FormDetails(submitForm, validateInfo);

  const changeActiveStatus = (e) => {
    let value = parseInt(e.target.id);
    let activeStatuses = { id: value, active: true };
    setActiveStatus(activeStatuses);
    //updating the service value
    if (activeStatus.id === value) {
      return;
    } else {
      // eslint-disable-next-line array-callback-return
      ContactData.map((data, index) => {
        if (index === value) {
          handleServiceSelection(data.TextIcon);
        }
      });
    }
  };

  return (
    <Wrapper>
      <MainWrapper>
        <ContactHeroSection widthchange={widthchanges} />
        <Container>
          <HeadWrap>
            <HeadingText>I'm interested in...</HeadingText>
            <HeadingSubText>*select one </HeadingSubText>
          </HeadWrap>

          <ServiceWrapper>
            {ContactData.map((data, index) => {
              return (
                <Content
                  id={index}
                  onClick={changeActiveStatus}
                  checkId={index}
                  active={activeStatus}
                >
                  {activeStatus.id === index && activeStatus.active ? (
                    <FullSelection>
                      <SelectionCheck />
                    </FullSelection>
                  ) : (
                    <EmptySelection />
                  )}

                  <Image
                    src={data.Icon}
                    alt={data.TextIcon}
                    id={index}
                    onClick={changeActiveStatus}
                  />

                  <ContentText id={index} onClick={changeActiveStatus}>
                    {data.TextIcon}
                  </ContentText>
                </Content>
              );
            })}
          </ServiceWrapper>

          <FormWrapper>
            <Form onSubmit={handleSubmit}>
              {submitError.submiterror && (
                <FormError>{submitError.submiterror}</FormError>
              )}
              <FormContentWrap>
                <FormContent>
                  <FormLabel htmlFor="unames">Name*</FormLabel>
                  <FormInput
                    id="unames"
                    type="text"
                    name="unames"
                    placeholder="Fullname"
                    value={values.unames}
                    onChange={handleChange}
                  />
                  {errors.unames && <FormError>{errors.unames}</FormError>}
                </FormContent>

                <FormContent>
                  <FormLabel htmlFor="email">E-mail*</FormLabel>
                  <FormInput
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && <FormError>{errors.email}</FormError>}
                </FormContent>
              </FormContentWrap>

              <FormContent>
                <FormLabel htmlFor="phoneNumber">Contact Number*</FormLabel>
                <FormInput
                  id="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  placeholder="254794268882"
                  value={values.phoneNumber}
                  onChange={handleChange}
                />
                {errors.phoneNumber && (
                  <FormError>{errors.phoneNumber}</FormError>
                )}
              </FormContent>
              <FormContent>
                <FormLabel htmlFor="message">Project description*</FormLabel>
                <FormTextArea
                  name="message"
                  placeholder="Tell us more"
                  value={values.message}
                  onChange={handleChange}
                />
                {errors.message && <FormError>{errors.message}</FormError>}
              </FormContent>
              <FormContentWrap>
                <FormP>
                  Your data is safe with us. We take your privacy seriously and
                  we will never share your data with anyone. Read on our
                  <a href="#">Privacy Policy</a>
                  to learn more.
                </FormP>
                {isSubmitting ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={80}
                    width={80}
                    timeout={5000}
                  />
                ) : (
                  <FormButton type="submit">LETS TALK</FormButton>
                )}
              </FormContentWrap>
            </Form>
          </FormWrapper>

          <ContactDetails widthchange={widthchanges}>
            <CallDetails>
              <h5>Call</h5>
              <p> +254 114283856</p>
            </CallDetails>
            <EmailDetails>
              <h5>E-mail</h5>
              <p>marketing@alero.co.ke</p>
            </EmailDetails>
          </ContactDetails>
        </Container>
      </MainWrapper>
    </Wrapper>
  );
};

export { ContactSection };
