import styled from "styled-components";

export const Wrapper = styled.div`
width:100%;
height:100%;
overflow:hidden;
`;

export const Container = styled.div``;

export const ServiceContainer = styled.div``;

export const ServiceDetailWrapper = styled.div`
display:none;
@media all and (min-width: 1366px){
    display:block;
    width: 1090px;
    margin:auto;
}
`;

export const ServiceDetailTitle = styled.h5``;
export const ServiceDetailContainer = styled.div`
  @media all and (min-width: 1366px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ServiceBrandDetail = styled.div`
width:369.83px;
height: 383.32px;
  .brand {
    height: 270px;
    width: 290.66px;
    margin-bottom: 10px;
  }
  .socialMedia {
      height: 260px;
      width: 220px;
      margin-bottom: 20px;
  }
`;

export const ServiceTitle = styled.p`
  &.brandsT {
    width: 220.66px;
    text-align: center;
    color: #f29a2e;
    font-weight:bold;
  }
  &.BusinessCTitle {
    margin-left: 20px;
  }
`;