import React from "react";
import { FaFacebook,  FaLinkedin, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import Logo from "../../../assets/Icons/AleroLogo.svg";
import {
  Wrapper,
  Container,
  CompanyDetails,
  LogoSection,
  CompanyHighlight,
  ContactDetails,
  Mobile,
  EmailDetails,
  SocialMediaPlatforms,
  ServicesDetails,
  ServiceTitle,
  ServiceContent,
  FooterDetails,
  FooterDetailTitle,
  FooterContent,
  CopyRightSection,
} from "./footerStyle";
const FooterSection = ({ FServices }) => {
  return (
    <Wrapper>
      <Container>
        <CompanyDetails>
          <LogoSection src={Logo} alt="Alero Logo" />
          <CompanyHighlight>
            Bring your business online.
            <br /> We can do it with you!
          </CompanyHighlight>
          <ContactDetails>
            <Mobile>Mobile: +254 114283856</Mobile>
            <EmailDetails>Email: marketing@alero.co.ke</EmailDetails>
          </ContactDetails>

          <SocialMediaPlatforms>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/alerogroup"
              name="twitter"
            >
              <FaTwitter />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/alerogroup/"
              name="facebook"
            >
              <FaFacebook />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/alerogroup"
              name="linkedIn"
            >
              <FaLinkedin />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/invites/contact/?i=5mc78ybmpyft&utm_content=ehp63qs"
              name="instagram"
            >
              <AiFillInstagram />
            </a>
          </SocialMediaPlatforms>
        </CompanyDetails>

        <ServicesDetails>
          <ServiceTitle>Services</ServiceTitle>
          {FServices.map((data, index) => {
            return (
              <ServiceContent to={data.path} key={index}>
                {data.title}
              </ServiceContent>
            );
          })}
        </ServicesDetails>

        <FooterDetails id="discover">
          <FooterDetailTitle>Discover</FooterDetailTitle>
          <FooterContent>Blog</FooterContent>
        </FooterDetails>

        <FooterDetails id="support">
          <FooterDetailTitle>Support</FooterDetailTitle>
          <FooterContent to="/comingsoon">Help & Learning</FooterContent>
          <FooterContent to="/comingsoon">Support</FooterContent>
          <FooterContent to="/comingsoon">Forum</FooterContent>
        </FooterDetails>

        <FooterDetails id="company">
          <FooterDetailTitle>Company</FooterDetailTitle>
          <FooterContent to="/about">About Us</FooterContent>
          <FooterContent to="/resources">Resources</FooterContent>
          <FooterContent to="/contact">Contact</FooterContent>
        </FooterDetails>
      </Container>

      <CopyRightSection>
        COPYRIGHT ALERO CORPORATION 2021 - TERMS | PRIVACY POLICY | COOKIES
      </CopyRightSection>
    </Wrapper>
  );
};

export { FooterSection };
