/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { TeamData } from "../data/TeamData";
import AboutSection from "../components/About";
import AboutMetaRoute from "../meta/About";
import {
  MobileMenu,
  Navigation,
  FooterSection,
 
} from "../components/common";
import Loader from '../components/loader';

import { FooterServices } from "../data/footerData";
const About = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);

   const [navSolid, setNavSolid] = useState(false);

  const [windowloading, setwindowloading] = useState(false)
  

  useEffect(() => {
   
  
      if(document.readyState === 'loading'){
      setwindowloading(true);
    } else if (document.readyState === 'interactive'){
      setwindowloading(true);
    }
    else if(document.readyState === 'complete'){
       setwindowloading(false)
    }
  

  }, []);

  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

 

  const loadedHandler = () =>{
    
    if(document.readyState === 'loading'){
      setwindowloading(true);
    } else if (document.readyState === 'interactive'){
      setwindowloading(true);
    }
    else if(document.readyState === 'complete'){
       setwindowloading(false)
    }
   
  }

   const navSolidChange = () => {
     if (window.scrollY >= 400) {
       setNavSolid(true);
     } else {
       setNavSolid(false);
     }
   };
 
  document.addEventListener("readystatechange", loadedHandler);
   window.addEventListener("scroll", navSolidChange);
 
  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
          <AboutMetaRoute />
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            displaywhite={true}
            menuColor="#264C2D"
            textColor="white"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />

          <AboutSection teamdata={TeamData} />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default About;
