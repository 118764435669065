import React from 'react'
import SuccessIcon from '../../../assets/Images/Foreground/SuccessIcon.svg';
import {
  SuccessWrapper,
  SuccessContainer,
  SuccessText,
  SuccessBtn,
  SuccessImg,
  SuccessTextmain,
} from "./styles";
const Success = () => {
   return (
     <SuccessWrapper>
       <SuccessContainer>
         <SuccessImg src={SuccessIcon} alt="Success" />
         <SuccessTextmain>SUCCESSFUL</SuccessTextmain>
         <SuccessText>Thank You! Message well received</SuccessText>
       

         <SuccessBtn to="/">BACK HOME</SuccessBtn>
       </SuccessContainer>
     </SuccessWrapper>
   );
}

export  {Success}
