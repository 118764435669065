import React from "react";
import { Helmet } from "react-helmet";


const AboutMetaRoute = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="about"
        content="Alero is a Product Design Agency
"
      />
      <meta
        name="description"
        content="alero help businesses transform concepts to digital products. 
        We believe the best digital solutions are built at the intersection of business strategy, 
        brand strategy, available technology, and real user’s needs. 
        We help you create a balanced approach by understanding the true needs of all influences – scalability, viability, and desirability."
      />

      <title>Alero | About us</title>
    </Helmet>
  </div>
);

export default AboutMetaRoute;
