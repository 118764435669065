import React from 'react'
import { ContentButtonWrap, ButtonText } from "./ButtonStyle";

const Button = ({ Btext, btnClass, widthChange, link }) => {
  return (
    <ContentButtonWrap btnClass={btnClass} widthchange={widthChange}>
      <ButtonText to={link}>{Btext}</ButtonText>
    </ContentButtonWrap>
  );
};

export {Button}
