import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaArrowRight } from "react-icons/fa";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeholder from "../../../assets/placeholder.png";
import {
  TopWrapper,
  ImageWrapper,
  ContentDetailWrapper,
  ContentWrapper,
  ContentText,
  ContentMainTextWrapper,
  ContentMainText,
  ContentSubDetailWrapper,
  ContentSubText,
  DetailButton,
  BtnText,
} from "./serviceStyle";
const ServiceDetail = ({serviceData, checkId}) => {
  
    return (
      <TopWrapper type={serviceData.Type}>
        <ImageWrapper
          type={serviceData.Type}
          picFalse={serviceData.displayfalse}
          picTrue={serviceData.displaytrue}
        >
          {serviceData.changeSize ? (
            <LazyLoadImage
              effect="blur"
              type={serviceData.Type}
              placeholderSrc={placeholder}
              className={`image `}
              id={"img" + checkId}
              src={serviceData.mainImage}
              Lbg={serviceData.mainImage}
              alt={serviceData.Title}
            />
          ) : (
            <LazyLoadImage
              effect="blur"
              placeholderSrc={placeholder}
              className={`image ${serviceData.ImageClass} `}
              src={serviceData.mainImage}
            />
          )}

          <ContentDetailWrapper
            className={`${serviceData.id}`}
            id={`${serviceData.textClass}`}
            type={serviceData.Type}
            boxShadow={serviceData.dropShadow}
            bgColor={serviceData.color}
          >
            <ContentWrapper id={serviceData.titleId} type={serviceData.Type}>
              <ContentText>{serviceData.Title}</ContentText>
            </ContentWrapper>

            <ContentMainTextWrapper
              id={serviceData.textId}
              type={serviceData.Type}
            >
              <ContentMainText type={serviceData.Type}>
                {serviceData.mainText}
              </ContentMainText>
            </ContentMainTextWrapper>
          </ContentDetailWrapper>
        </ImageWrapper>

        <ContentSubDetailWrapper
          subtextfalse={serviceData.subtextfalse}
          type={serviceData.Type}
          dropShadow={serviceData.subDropShadow}
        >
          <ContentSubText type={serviceData.Type}>
            {serviceData.subText}
          </ContentSubText>
          {serviceData.subDetailBtn && (
            <DetailButton to={serviceData.subBtnLink}>
              <BtnText>Learn more</BtnText> <FaArrowRight />
            </DetailButton>
          )}
        </ContentSubDetailWrapper>
      </TopWrapper>
    );
}

export {ServiceDetail}
