import { Link } from "react-router-dom";
import styled from "styled-components";

export const SHeroWrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow:hidden;
  z-index: -6;
  background: linear-gradient(180deg, #fde1a9 0%, #fdebc7 32.81%, #ffffff 100%);
`;

export const SHeroContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 112px;

  @media all and (min-width: 1366px){
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "Text Image";
    justify-content:center;
    align-items:center;

  }
 
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  @media all and (min-width: 360px) {
    margin-top: 20px;
  }
  @media all and (min-width: 1366px){
    grid-area: Image;
  }
`;
export const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 10;
  @media all and (min-width: 1366px) {
    left: 170px;
  }
  @media all and (min-width: 1523px) {
    left: 240px;
  }

  .simage {
    width: 221px;
    height: 307.3px;
    z-index: 6;

    @media all and (min-width: 360px) {
      width: 281px;
      height: 307.3px;
    }
    @media all and (min-width: 478px) {
      width: ${({ resize }) => `${resize * 0.7}`}px;
      height: ${({ resize }) => `${resize * 0.7}`}px;
    }
    @media all and (min-width: 500px) {
      width: 350px;
      height: 350px;
    }

    @media all and (min-width: 1366px) {
      width: 460px;
      height: 460px;
    }
  }
`;
export const Image = styled.img`
 

`;

export const Frame1 = styled.div`
  width: 74.19px;
  height: 74.19px;
  border: 1.6833px solid #3a7a44;
  transform: rotate(-3.69deg);
  position: absolute;
  top: 50px;
  z-index: -2;
  left: -23px;

  @media all and (min-width: 360px) {
    top: 30px;
  }
  @media all and (min-width: 478px) {
    top: ${({ resize }) => `${resize * 0.05}`}px;
    left: ${({ resize }) => `${resize * -0.04}`}px;
  }

  @media all and (min-width: 500px){
      top:25px;
      left:-20px;
  }
 
`;

export const Frame2 = styled.div`
  width: 38.81px;
  height: 38.81px;
  background: #a5c77d;
  transform: rotate(9.4deg);
  position: absolute;
  top: 233px;
  z-index: -2;
  left: 113px;
  @media all and (min-width: 360px) {
    top: 254px;
    left: 123px;
  }
  @media all and (min-width: 478px) {
    top: ${({ resize }) => `${resize * 0.6}`}px;
    left: ${({ resize }) => `${resize * 0.35}`}px;
  }
  @media all and (min-width:500px){
      top: 300px;
      left:175px;
  }

`;

export const Frame3 = styled.div`
  width: 130px;
  height: 130px;
  background: #f2c335;
  position: absolute;
  z-index: -2;
  top: 20px;
  left: 70px;

  @media all and (min-width: 360px) {
    top: 10px;
    left: 130px;
  }
  @media all and (min-width: 478px) {
    top: ${({ resize }) => `${resize * 0.008}`}px;
    left: ${({ resize }) => `${resize * 0.39}`}px;
  }
  @media all and (min-width: 500px){
      top:4px;
      left:195px;
  }
 
`;

export const TextContentWrap = styled.div`
  width: 100%;
  @media all and (min-width: 1366px) {
    grid-area: Text;
    width: 391.2px;
    margin:auto;
  }
`;

export const TextContentHead = styled.div`
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #264d2d;
  width: 94%;
  margin: auto;
  margin-bottom: 20px;

  @media all and (min-width: 768px) {
    font-size: 40px;
  }
  @media all and (min-width: 1366px) {
    text-align: left;
    width: 591.2px;
  }
`;

export const TextContent = styled.p`
  line-height: 30px;
  text-align: center;
  font-size: 20px;
  width: 80%;
  margin: auto;
  color: #151515;
  font-weight: 400;
  margin-bottom: 23px;

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 591.2px;
  }
  @media all and (min-width: 1366px) {
    text-align: left;
    width: 591.2px;
  }
`;

export const ContentButtonWrap = styled.div`
  background: radial-gradient(135% 135% at 5.8% 0%, #a5c77d 0%, #3a7a44 100%);
  width: 265px;
  margin:auto;
  padding: 16px 57px;
  border-radius: 3rem;
`;

export const Button = styled(Link)`
  text-decoration: none;
  font-size: 19.3902px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
`;