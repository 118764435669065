import React from "react";
import { Helmet } from "react-helmet";


const Home = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="home" content="Alero is a Product Design Agency" />
      <meta
        name="description"
        content="Branding, Web development & Ecommerce team building jamstack, PWA, React.js and Node.js applications for enterprises and online stores. Let's talk!"
      />
      <meta
        property="og:title"
        content="Alero: Web and Mobile Development Company based in Kenya"
      />
      <meta
        property="og:image"
        content="https://alero.co.ke/assets/images/ogfacebook.jpg"
      />

      <meta property="og:url" content="https://alero.co.ke"/>
      <meta property="og:type" content="website"/>
      <meta
        name="facebook-domain-verification"
        content="0cfjbughxbvyx626tdz5psltn5gfm7"
      />
      <title>Alero | Website Design & Development</title>
    </Helmet>
  </div>
);

export default Home;
