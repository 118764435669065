import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #3a7a44;
`;

export const Container = styled.div`
  color: white;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;

  @media all and (min-width: 1000px) {
    display: flex;
    width: 1000px;
    margin: auto;
    align-items: center;
    padding-bottom: 20px;
  }
`;

export const SupportText = styled.div`
  width: 80%;
  margin: auto;
  margin-bottom: 40px;

  @media all and (min-width: 1000px) {
    width: 719px;
    font-size: 20px;
    padding: 36px 46px;
    margin-bottom: 20px;
  }
`;

export const SupportBtnWrap = styled(Link)`
  text-decoration: none;
  border: 2px solid white;
  width: 181px;
  margin: auto;
  border-radius: 50px;
  padding: 15px 30px;
  color: white;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    border: 1.75px solid #ffffff;
  }
  @media all and (min-width: 1000px) {
    width: 181px;
    font-size: 15px;
    padding: 20px 40px;
  }
`;

export const Button = styled(Link)`
text-decoration:none;
color:inherit;
outline:none;

`;