import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeholder from "../../../assets/placeholder.png";
import { Button } from "../../common";
import {
  Wrapper,
  Container,
  TextWrapper,
  TextContent,
  SubText,
 
  BrandWrapper,
  TextContentContainer,
} from "./brandstyles";
const BrandExamples = ({ BrandingDetail }) => {
  return (
    <Wrapper>
      <Container>
        <TextWrapper>
          <TextContentContainer>
            <TextContent>Brands made by our Designers</TextContent>
            <SubText>Some of our clients brands.</SubText>
          </TextContentContainer>

          <Button Btext="Get An Estimate" widthChange={true} link="/contact" />
        </TextWrapper>

        <Swiper>
          {BrandingDetail.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <BrandWrapper>
                  <LazyLoadImage
                    effect="blur"
                    placeholderSrc={placeholder}
                    className="brandImages"
                    src={data.src}
                    alt={data.title}
                  />
                </BrandWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </Wrapper>
  );
};

export  {BrandExamples}
