import React, { useEffect, useState } from "react";
import VisualBrandMetaRoute from "../meta/VisualBrand";
import VisualIdentitySection from "../components/VisualIdentity";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  HeroWork,
  SupportSection,
} from "../components/common";

import { WorkTitle, IdentityWork } from "../data/identityData";
import { FooterServices } from "../data/footerData";
import Loader from "../components/loader";
const VisualIdentity = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
   const [navSolid, setNavSolid] = useState(false);
  let scrolle;

  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    let scrolle = window.screen.width;

    let total = scrolle;
    setScrollWidth(total);

  if (document.readyState === "loading") {
    setwindowloading(true);
  } else if (document.readyState === "interactive") {
    setwindowloading(true);
  } else if (document.readyState === "complete") {
    setwindowloading(false);
  }
  }, [windowloading]);

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  const loadedHandler = () => {
    if (document.readyState === "loading") {
      setwindowloading(true);
    } else if (document.readyState === "interactive") {
      setwindowloading(true);
    } else if (document.readyState === "complete") {
      setwindowloading(false);
    }
  };

   const navSolidChange = () => {
     if (window.scrollY >= 400) {
       setNavSolid(true);
     } else {
       setNavSolid(false);
     }
   };
  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
    window.addEventListener("scroll", navSolidChange);

  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <VisualBrandMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FDE1A9"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <HeroWork details={WorkTitle} widthCheck={scrollWidth} />
          <VisualIdentitySection Identity={IdentityWork} />
          <SupportSection />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default VisualIdentity;
