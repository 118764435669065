/* eslint-disable no-unused-vars */
import React from "react";

import aleroplaceholder from '../../assets/aleroPlaceholder.png';
import polygon from "../../assets/Images/Background/Polygon.svg";
import polygon2 from "../../assets/Images/Background/polyIcon2.svg";
import ppmarks from "../../assets/Images/Foreground/ppmarks.svg";
import { Button } from "../../components/common";
//import { Testimonies } from "../../data/TestimoniesData";
//import { TestimonySection } from "../Index";
import {
  Wrapper,
  Container,
  Hero,
  IntroWrapper,
  IntroTitle,
  IntroText,
  IntroSubTitle,
  ContentDetailWrapper,
  ContentImages,
  ContentDetailTextWrapper,
  ContentDetailTitle,
  ContentDetailText,
  ButtonWrapper,
  ButtonContainer,
  Frame,
  ButtonContentText,
  TeamDetailWrapper,
  TeamTitle,
  TeamPhotoWrapper,
  ContactDetailWrapper,
  ContactDetailTitle,
  ContactDetailContainer,
  ContactDetailLocation,
  ContactDetailSubTitle,
  ContactPhone,
  ContactEmail,
  IntroTextWrapper,
  ContentDetailContainer,
  ContactDetailWrap,
  ContactDetailSubTitleWrap,
  PhotoWrap,
  PhotoText,
  BackIcons,
  Frame1,
  Frame2,
  Frame3,
} from "./aboutstyles";
const AboutSection = ({ teamdata }) => {
  return (
    <Wrapper>
      <Container>
        <Hero>
          <video
            autoPlay={true}
            src="https://ik.imagekit.io/dk30bxu4rix/Website/assets/video/time-extended_1__aZVESuIUC.mp4"
            muted
            loop
            playsinline
            poster={aleroplaceholder}
            type="video/mp4"
            alt="about-video"
          />
        </Hero>

        <IntroWrapper>
          <IntroTitle>Who we are</IntroTitle>

          <img src={ppmarks} alt="bgmarks" />
          <IntroSubTitle>
            Take your digital platforms to the next level.
          </IntroSubTitle>
          <IntroTextWrapper>
            <IntroText>
              Alero is a digital branding & software development agency. We are
              focused on helping Micro, Small and Medium Enterprises utilize
              technology and data driven strategies to improve the growth of
              their businesses.
            </IntroText>
            <IntroText>
              We use design and engineering to produce customised, user-centric
              digital products that meet global standards. We also provide
              business and technology consultation to clients who want to take
              their digital platforms to the next level.{" "}
            </IntroText>
          </IntroTextWrapper>
        </IntroWrapper>

        <ContentDetailWrapper>
          <ContentDetailContainer>
            <ContentImages bg={"#fbedc2"}>
              <img
                className="images"
                src="https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/About/vision_TAt2qdulr6.svg"
                alt="Mission"
              />
              <img className="icon" src={polygon} alt="Mission-icon" />
            </ContentImages>
            <ContentDetailTextWrapper>
              <ContentDetailTitle>Our Mission</ContentDetailTitle>
              <ContentDetailText>
                We strive to improve the values, standards and networks of
                digital products and in Africa.
              </ContentDetailText>
            </ContentDetailTextWrapper>
          </ContentDetailContainer>

          <ButtonWrapper>
            <ButtonContainer>
              <Frame />
              <ButtonContentText>
                Need to rebrand your business?
              </ButtonContentText>
              <Button Btext="START NOW" link="/contact" />
            </ButtonContainer>
          </ButtonWrapper>
        </ContentDetailWrapper>

        <ContentDetailWrapper>
          <ContentDetailContainer>
            <ContentImages bg={"#DEF3CF"} className="imagesleft">
              <img
                className="images"
                src="https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/About/mission_uKx9GNKxx.svg"
                alt="Vision"
              />
              <img className="icon2" src={polygon2} alt="Vision-icon" />
            </ContentImages>
            <ContentDetailTextWrapper className="Textleft">
              <ContentDetailTitle>Our Vision</ContentDetailTitle>
              <ContentDetailText>
                We believe that growth and development occurs where there is
                movement. Development of industries depends on consistent growth
                of businesses and innovation.
              </ContentDetailText>
            </ContentDetailTextWrapper>
          </ContentDetailContainer>
        </ContentDetailWrapper>
    {/*
      
       <TeamDetailWrapper>
          <TeamTitle>Our Team</TeamTitle>
          <TeamPhotoWrapper>
            {teamdata.map((data, index) => {
              return (
                <PhotoWrap key={index} left={data.left} top={data.top}>
                  <img src={data.profile} alt={data.name} />
                  <PhotoText>{data.name}</PhotoText>
                </PhotoWrap>
              );
            })}
          </TeamPhotoWrapper>
          <BackIcons>
            <Frame1 className="icon2" src={polygon2} alt="icon" />
            <Frame2 className="icon2" src={polygon2} alt="icon" />
            <Frame3 className="icon2" src={polygon2} alt="icon" />
          </BackIcons>
        </TeamDetailWrapper>
    */}
       

        <ContactDetailWrapper>
          <ContactDetailTitle>Where to find us?</ContactDetailTitle>
          <ContactDetailWrap>
            <ContactDetailContainer>
              <ContactDetailLocation>Nairobi, Kenya</ContactDetailLocation>
              <ContactDetailSubTitleWrap>
                <ContactDetailSubTitle>Call</ContactDetailSubTitle>
                <ContactPhone>+254 114283856</ContactPhone>
                <ContactPhone>+254 729 245657</ContactPhone>
                <ContactPhone>+254 701 113792</ContactPhone>
              </ContactDetailSubTitleWrap>

              <ContactDetailSubTitle>E-mail</ContactDetailSubTitle>
              <ContactEmail>marketing@alero.co.ke</ContactEmail>
            </ContactDetailContainer>

            <ContactDetailContainer>
              <ContactDetailLocation>Kampala, Uganda</ContactDetailLocation>
              <ContactDetailSubTitleWrap>
                <ContactDetailSubTitle>Call</ContactDetailSubTitle>
                <ContactPhone>+254 114283856</ContactPhone>
                <ContactPhone>+256 787785114</ContactPhone>
              </ContactDetailSubTitleWrap>

              <ContactDetailSubTitle>E-mail</ContactDetailSubTitle>
              <ContactEmail>marketing.ug@alero.co.ke</ContactEmail>
            </ContactDetailContainer>
          </ContactDetailWrap>
        </ContactDetailWrapper>
      </Container>
    </Wrapper>
  );
};

export default AboutSection;

 // <TestimonySection
        //   subText={"What our customers say about us?"}
        //   TestimonyData={Testimonies}
        // />