import Vue from "../assets/Logos/logos_vue.svg";
import Android from "../assets/Logos/logos_android-icon.svg";
import Node from "../assets/Logos/logos_nodejs.svg";
import Ios from "../assets/Logos/logos_ios.svg";
import React from "../assets/Logos/logos_react.svg";
import Figma from "../assets/Logos/logos_figma.svg";
import Trello from "../assets/Logos/logos_trello.svg";
import Netlify from "../assets/Logos/logos_netlify.svg";
import Slack from "../assets/Logos/logos_slack.svg";
export const ImageData = [
  {
    type: "Vue",
    image: Vue,
    Swidth: "41px",
    Sheight: "36px",
    Mwidth: "71.52px",
    Mheight: "61.74px",
    Lwidth: "70.14px",
    Lheight: "64px",
    SouterWidth: "67.79px",
    SouterHeight: "67.79px",
    MouterWidth: "115.76px",
    MouterHeight: "115.76px",
    LouterWidth: "114px",
    LouterHeight: "120px",
    smSizeLeft: 0.05,
    mmSizeLeft: 0.15,
    mediumSizeLeft: 0.27,
  },
  {
    type: "Android",
    image: Android,
    Swidth: "28px",
    Sheight: "33px",
    Mwidth: "49.05px",
    Mheight: "57.67px",
    Lwidth: "46.84px",
    Lheight: "59.78px",
    SouterWidth: "52.63px",
    SouterHeight: "52.63px",
    MouterWidth: "89.87px",
    MouterHeight: "89.87px",
    LouterWidth: "89.16px",
    LouterHeight: "93.16px",
    smSizeLeft: 0.45,
    smSizetop: "2rem",
    mmSizeLeft: 0.55,
    mmSizeTop: "2.5rem",
    mediumSizeLeft: 0.5,
    mediumSizetop: "2rem",
  },
  {
    type: "Netlify",
    image: Netlify,
    Swidth: "40.49px",
    Sheight: "40.49px",
    Mwidth: "69.14px",
    Mheight: "69.14px",
    Lwidth: "67.67px",
    Lheight: "71.67px",
    SouterWidth: "67.79px",
    SouterHeight: "67.79px",
    MouterWidth: "115.76px",
    MouterHeight: "115.76px",
    LouterWidth: "114px",
    LouterHeight: "120px",
    smSizeLeft: 0.8,
    smSizetop: "3.5rem",
    mmSizeLeft: 0.84,
    mmSizeTop: "3.8rem",
    mediumSizeLeft: 0.75,
    mediumSizetop: "2.5rem",
  },
  {
    type: "Trello",
    image: Trello,
    Swidth: "41px",
    Sheight: "36px",
    Lwidth: "51.68px",
    Lheight: "56.68px",
    LouterWidth: "89.16px",
    LouterHeight: "93.16px",
    mediumSizetop: "10rem",
    mediumSizeLeft: 0.9,
  },
  {
    type: "Figma",
    image: Figma,
    Swidth: "27.12px",
    Sheight: "40.68px",
    Mwidth: "46.3px",
    Mheight: "69.46px",
    Lwidth: "44px",
    Lheight: "72px",
    SouterWidth: "67.79px",
    SouterHeight: "67.79px",
    MouterWidth: "115.76px",
    MouterHeight: "115.76px",
    LouterWidth: "114px",
    LouterHeight: "120px",
    smSizetop: "27rem",
    smSizeLeft: 0.8,
    mmSizeLeft: 0.84,
    mmSizeTop: "27rem",
    mediumSizetop: "24rem",
    mediumSizeLeft: 0.87,
  },
  {
    type: "Slack",
    image: Slack,
    Swidth: "31.58px",
    Sheight: "31.58px",
    Mwidth: "53.92px",
    Mheight: "53.92px",
    Lwidth: "51.9px",
    Lheight: "55.9px",
    SouterWidth: "52.63px",
    SouterHeight: "52.63px",
    MouterWidth: "89.87px",
    MouterHeight: "89.87px",
    LouterWidth: "89.16px",
    LouterHeight: "93.16px",
    smSizetop: "29rem",
    smSizeLeft: 0.45,
    mmSizeLeft: 0.45,
    mmSizeTop: "35rem",
    mediumSizetop: "33rem",
    mediumSizeLeft: 0.5,
  },
  {
    type: "React",
    image: React,
    Swidth: "44.4px",
    Sheight: "39.55px",
    Mwidth: "75.82px",
    Mheight: "67.53px",
    Lwidth: "74.6px",
    Lheight: "70px",
    SouterWidth: "67.79px",
    SouterHeight: "67.79px",
    MouterWidth: "115.76px",
    MouterHeight: "115.76px",
    LouterWidth: "114px",
    LouterHeight: "120px",
    smSizetop: "27rem",
    smSizeLeft: 0.05,
    mmSizeLeft: 0.05,
    mmSizeTop: "29rem",
    mediumSizetop: "30rem",
    mediumSizeLeft: 0.16,
  },
  {
    type: "Ios",
    image: Ios,
    Swidth: "41px",
    Sheight: "36px",
    Lwidth: "39.28px",
    Lheight: "21.56px",
    SouterWidth: "52.63px",
    SouterHeight: "52.63px",
    LouterWidth: "93.16px",
    LouterHeight: "93.16px",
    mediumSizetop: "20rem",
    mediumSizeLeft: 0.06,
  },
  {
    type: "Node",
    image: Node,
    Swidth: "41px",
    Sheight: "36px",
    Lwidth: "91.89px",
    Lheight: "58.81px",
    SouterWidth: "52.63px",
    SouterHeight: "52.63px",
    LouterWidth: "114px",
    LouterHeight: "120px",
    mediumSizetop: "8rem",
    mediumSizeLeft: 0.07,
  },
];

export const InfoData = [
  {
    title: "We care alot about quality",
    text: `Using modern languages, frameworks and
                  solid development process to help businesses
                  stay ahead in tech.`,
  },
  {
    title: "Helping you at every stage.",
    text: `From consulting & discovery sessions, wireframing, UX/UI design & MVP development, to creating an end product.`,
  },
  {
    title: "Preparing you for growth.",
    text: ` We harness the principles and standards from design right through
Implementation to ensure your project grows from MVP to enterprise solutions.
`,
  },
];
