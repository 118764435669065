import React from "react";
import aleroLogo from '../../../assets/Images/AleroLogo/icon.svg';
import WebLogoWhite from '../../../assets/Images/AleroLogo/Logo – Long – White.svg';
import WebLogoGreen from '../../../assets/Images/AleroLogo/Logo – Long  – Green.svg';
import aleroLogoWhite from '../../../assets/Images/AleroLogo/aleroLogoWhite.svg';
import { FiMenu } from "react-icons/fi";
import { NavData } from "../../../data/NavigationData";
import SubMenu from "../SubMenu";
import {
  Nav,
  MobileIcon,
  NavMenu,
  NavBtnWrap,
  NavBtn,
  NavMenuWrap,
  LogoIcon,
  MenuIconWrap,
  WebLogoIcon,
  ContainerWrap,
} from "./navigationStyle";
const Navigation = ({
  toggle,
  mobileActive,
  menuColor,
  displayfalse,
  textColor,
  displaywhite,
  navchange, 
  screenWidth
}) => {
  return (
    <Nav menuColor={menuColor} navchange={navchange}>
      <ContainerWrap menuColor={menuColor}>
        <MenuIconWrap displayfalse={displayfalse}>
          <LogoIcon
            displayfalse={displayfalse}
            displaywhite={displaywhite}
            to="/"
          >
            <img className="aleroGreen" src={aleroLogo} alt="Alero" />
            <img className="aleroWhite" src={aleroLogoWhite} alt="Alero" />
          </LogoIcon>
          <MobileIcon textcolor={textColor} activeMobile={mobileActive}>
            <FiMenu onClick={toggle} />
          </MobileIcon>
        </MenuIconWrap>

        <NavMenuWrap>
          <WebLogoIcon
            displayfalse={displayfalse}
            displaywhite={displaywhite}
            to="/"
          >
            <img className="whiteLogo" src={WebLogoWhite} alt="Alero Logo" />
            <img className="GreenLogo" src={WebLogoGreen} alt="Alero Logo" />
          </WebLogoIcon>
          <NavMenu>
            {NavData.map((item, index) => {
              return (
                <SubMenu
                  screenwidth={screenWidth}
                  textColor={textColor}
                  item={item}
                  key={index}
                />
              );
            })}
            <NavBtnWrap>
              <NavBtn textcolor={textColor} to="/contact">
                Book A Call
              </NavBtn>
            </NavBtnWrap>
          </NavMenu>
        </NavMenuWrap>
      </ContainerWrap>
    </Nav>
  );
};

export { Navigation };
