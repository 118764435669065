import React, { useEffect, useState } from "react";
import EcommerceMetaRoute from "../meta/Ecommerce";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  Lists,
} from "../components/common";
import { ServiceHeroSection } from "../components/ServiceCommerce";
import { FooterServices } from "../data/footerData";
import { EcommerceServices, EcommerceData } from "../data/EcommerceData";
import Loader from "../components/loader";

const Commerce = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
  const [navSolid, setNavSolid] = useState(false);
  let scrolle;
  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    let scrolle = window.screen.width;

    let total = scrolle;
    setScrollWidth(total);

    if (document.readyState === "loading") {
      setwindowloading(true);
    } else if (document.readyState === "interactive") {
      setwindowloading(true);
    } else if (document.readyState === "complete") {
      setwindowloading(false);
    }
  }, [windowloading]);

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  const loadedHandler = () => {
     if (document.readyState === "loading") {
       setwindowloading(true);
     } else if (document.readyState === "interactive") {
       setwindowloading(true);
     } else if (document.readyState === "complete") {
       setwindowloading(false);
     }
  };

  const navSolidChange = () => {
    if (window.scrollY >= 400) {
      setNavSolid(true);
    } else {
      setNavSolid(false);
    }
  };

  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
   window.addEventListener("scroll", navSolidChange);
  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <EcommerceMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FDE1A9"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <ServiceHeroSection widthCheck={scrollWidth} />
          <Lists TabsData={EcommerceServices} serviceData={EcommerceData} />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Commerce;
