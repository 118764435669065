import React, { useEffect, useState } from "react";
import BrandingMetaRoute from "../meta/Branding";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  Lists,
} from "../components/common";
import { BrandingHeroSection } from "../components/Branding";
import { FooterServices } from "../data/footerData";
import {
  BrandingServices,
  BrandingData,
  BrandingDetail,
} from "../data/BrandingData";
import Loader from "../components/loader";

const Branding = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
   const [navSolid, setNavSolid] = useState(false);
  let scrolle;
  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    let scrolle = window.screen.width;

    let total = scrolle;
    setScrollWidth(total);

 if (document.readyState === "loading") {
   setwindowloading(true);
 } else if (document.readyState === "interactive") {
   setwindowloading(true);
 } else if (document.readyState === "complete") {
   setwindowloading(false);
 }

  }, [windowloading]);

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  const loadedHandler = () => {
    if (document.readyState === "loading") {
      setwindowloading(true);
    } else if (document.readyState === "interactive") {
      setwindowloading(true);
    } else if (document.readyState === "complete") {
      setwindowloading(false);
    }
  };

  const navSolidChange = () => {
    if (window.scrollY >= 400) {
      setNavSolid(true);
    } else {
      setNavSolid(false);
    }
  };
  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
   window.addEventListener("scroll", navSolidChange);

  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <BrandingMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FDE1A9"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <BrandingHeroSection widthCheck={scrollWidth} />
          <Lists
            brandExamples={true}
            listStyle="2"
            BrandingDetail={BrandingDetail}
            TabsData={BrandingServices}
            serviceData={BrandingData}
          />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Branding;
