import React from 'react'
import aleroSpinner from '../../assets/Images/AleroLogo/logo-icon-loader.svg';
import { LoaderWrapper, LoaderContainer } from "./loaderstyle";
const Loader = () => {
    return (
      <LoaderWrapper>
        <LoaderContainer>
          <img className='logo-spin' src={aleroSpinner} alt="" />
        </LoaderContainer>
      </LoaderWrapper>
    );
}

export default Loader
