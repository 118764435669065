import React, { useEffect, useState } from "react";
import ResourcesMetaRoute from "../meta/Resources";
import {
  MobileMenu,
  Navigation,
  FooterSection,
  SupportSection,
} from "../components/common";
import { EsourcesHeroSection, EsourceSection } from "../components/Esouces";
import { pressData, OpenSourceData } from "../data/esourceData";
import { FooterServices } from "../data/footerData";
import Loader from "../components/loader";
const Esources = () => {
  const [openMobileMenu, setMobileMenu] = useState(false);
  const [scrollWidth, setScrollWidth] = useState();
  const [windowloading, setwindowloading] = useState(false);
  const [navSolid, setNavSolid] = useState(false);
  let scrolle;

  const toggle = () => {
    setMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    let scrolle = window.screen.width;

    let total = scrolle;
    setScrollWidth(total);

     if (document.readyState === "loading") {
       setwindowloading(true);
     } else if (document.readyState === "interactive") {
       setwindowloading(true);
     } else if (document.readyState === "complete") {
       setwindowloading(false);
     }
  }, [windowloading]);

  const widthChange = () => {
    scrolle = window.screen.width;
    let total = scrolle;
    setScrollWidth(total);
  };

  const loadedHandler = () => {
   if (document.readyState === "loading") {
     setwindowloading(true);
   } else if (document.readyState === "interactive") {
     setwindowloading(true);
   } else if (document.readyState === "complete") {
     setwindowloading(false);
   }
  };

  const navSolidChange = () => {
    if (window.scrollY >= 200) {
      setNavSolid(true);
    } else {
      setNavSolid(false);
    }
  };
  window.addEventListener("resize", widthChange);
  document.addEventListener("readystatechange", loadedHandler);
   window.addEventListener("scroll", navSolidChange);

  return (
    <>
      {windowloading && <Loader />}
      {!windowloading && (
        <>
        <ResourcesMetaRoute/>
          <MobileMenu mobileActive={openMobileMenu} toggle={toggle} />
          <Navigation
            menuColor="#FDE1A9"
            toggle={toggle}
            mobileActive={openMobileMenu}
            navchange={navSolid}
          />
          <EsourcesHeroSection widthCheck={scrollWidth} />
          <EsourceSection
            pressData={pressData}
            OpenSourceData={OpenSourceData}
          />
          <SupportSection />
          <FooterSection FServices={FooterServices} />
        </>
      )}
    </>
  );
};

export default Esources;
