export const WorkTitle = {
  title: "Excellent Websites. Build with us the digital product",
  text:
    "Entrepreneurs, Small businesses, self-employed, artists, bloggers - our clients are the creative minds behind the great websites. We’ve had the great pleasure to work for companies from markets like FoodTech, E-commerce, EdTech, Beauty & many more",
  BtnText: "BOOK A CALL",
};

export const ClientWork = [
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_60-min_Jsb4hDjH0.png",
    alt: "security",
    to: "https://brickcom.alero.co.ke/",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_63_piMIZRhKml.png",
    alt: "LociFoods",
    to: "https://locifoods.co.ke/",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_68_D6kodPcwG.png",
    alt: "PaintDepo",
    to: "https://thepaintdepo.co.ke/",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_69-min_j6RlHQtVD.png",
    alt: "LMS",
    to: "https://alero.co.ke/comingsoon",
  },
];

export const OurProjects = [
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_72_YAMMaH3uU.png",
    alt: "personal",
    to: "https://alero.co.ke/comingsoon",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_71-min_FqWwDY-g-F.png",
    alt: "innovation",
    to: "https://alero.co.ke/comingsoon",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_65-min_rxJ7Cr5Fm7.png",
    alt: "Stephanie",
    to: "https://stephaniekirathe@alero.co.ke/",
  },
  {
    link:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Inspire/Frame_64-min_UCefR3-WtV.png",
    alt: "Sifawear",
    to: "https://alero.co.ke/comingsoon",
  },
];
