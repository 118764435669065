import React from "react";
import { Wrapper, Container, Frame, ContentText } from "./stylebutton";
import { Button } from "../../common";
const ServiceButton = ({Head, BtnText, link}) => {
  
  return (
    <Wrapper>
      <Container>
        <Frame />
        <ContentText>{Head}</ContentText>
        <Button Btext={BtnText} link={link}/>
      </Container>
    </Wrapper>
  );
};

export { ServiceButton };
