

export const WorkData = [
  {
    id: 1,
    color:
      "radial-gradient(25.36% 25.36% at 65.36% 22.02%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, #0699CF 13.21%, #07C1DE 94.29%)",
    name: "LociFoods",
    Image:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Home/LocifoodEcommerse_h9ZLRnbJJC.png",
    area: "loci",
    buttonLink: "/comingsoon",
  },
  {
    id: 2,
    color:
      "radial-gradient(50% 50% at 50% 50%, rgba(249, 180, 40, 0.46) 0%, #F9B428 100%)",
    name: "Custom e-commerce",
    Image:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Home/Jireh_1hcnwvoP-.png",
    area: "erp",
    buttonLink: "/ecommerce",
  },
  {
    id: 3,
    color:
      "radial-gradient(70.91% 70.91% at 78.33% 29.09%, rgba(38, 42, 163, 0.6) 0%, #262AA3 100%)",
    Image:
      "https://ik.imagekit.io/dk30bxu4rix/Website/assets/images/Home/Brickom_ComoqHvZD.png",
    name: "Inspiration",
    area: "insp",
    buttonLink: "/work",
  },
];
