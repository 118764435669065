import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TopWrapper = styled.div`
  margin-bottom: 54px;
  @media (min-width: 768px) and (max-width: 1365px) {
    width: 750px;
    margin: auto;
  }
  @media all and (min-width: 1366px) {
    background: #ffffff;
    height: ${({ type }) => (type === "Full" ? "600px" : "600px")};
    position: relative;
    width: 1090px;
    margin: auto;
    margin-bottom: 30px;
    position: relative;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: ${({ picFalse }) => (picFalse ? "none" : "block")};

    @media all and (min-width: 1366px) {
      position: ${({ type }) => (type === "Full" ? "relative" : "absolute")};
      width: ${({ type }) => (type === "Full" ? "100%" : "413px")};
      height: ${({ type }) => (type === "Full" ? "300px" : "513px")};
      left: ${({ type }) => (type === "Full" ? "" : "130px")};
      top: ${({ type }) => (type === "Full" ? "" : "0px")};
      display: block;
      z-index: -10;
    }
  }
  .office {
    height: 200px;
    width: 100%;

    object-fit: center;

    @media (min-width: 768px) and (max-width: 1365px) {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
    @media all and (min-width: 1366px) {
      position: ${({ type }) => (type === "Full" ? "relative" : "absolute")};
      width: ${({ type }) => (type === "Full" ? "100%" : "413px")};
      height: ${({ type }) => (type === "Full" ? "300px" : "513px")};
      left: ${({ type }) => (type === "Full" ? "" : "130px")};
      top: ${({ type }) => (type === "Full" ? "" : "0px")};
      display: block;
      z-index: -10;
    }
  }
  #img1 {
    width: 320px;
    height: 400px;
    object-fit: cover;
    @media all and (min-width: 1366px) {
      background: #ffffff;
      width: 400px;
      height: 513px;
      object-fit: contain;
      position: absolute;
      left:-540px;
    }
  }
  #img0 {
    width: 500px;
    height: 350px;
    object-fit: cover;

    @media all and (max-width: 472px) {
      object-fit: contain;
    }
    @media all and (max-width: 436px) {
      object-fit: contain;
      width: 350px;
    }
    @media all and (min-width: 1366px) {
      background: #fff5e8;
      width: 400px;
      height: 513px;
      object-fit: contain;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 750px;
    margin: auto;
  }
  @media all and (min-width: 1366px) {
    width: 1090px;
    margin: auto;
    position: relative;
  }
`;

export const Image = styled.div`
  background: url(${({ sbg }) => sbg}) no-repeat;
  background-size: cover;
  
  width: 400px;
  height:200px;
`;

export const ContentDetailWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  box-shadow: ${({ boxShadow }) =>
    boxShadow ? " 7.89586px 7.89586px 20.6507px rgba(0, 0, 0, 0.129)" : "none"};
  border-radius: 2.71825px;
  @media all and (min-width: 1366px) {
    width: ${({ type }) => (type === "Half" ? "62%" : "500px")};
    height: ${({ type }) => (type === "Full" ? "353px" : "513px")};
    position: ${({ type }) => (type === "Full" ? "absolute" : "absolute")};
    left: ${({ type }) => (type === "Full" ? "70px" : "0")};
    top: ${({ type }) => (type === "Full" ? "180px" : "0")};
  }
  &#officeText {
    @media all and (min-width: 1366px) {
      width: ${({ type }) => (type === "Half" ? "62%" : "500px")};
      height: ${({ type }) => (type === "Full" ? "453px" : "513px")};
      position: ${({ type }) => (type === "Full" ? "absolute" : "absolute")};
      left: ${({ type }) => (type === "Full" ? "70px" : "0")};
      top: ${({ type }) => (type === "Full" ? "180px" : "0")};
      
    }
  }
  &.experience {
    @media all and (min-width: 1366px){
      
      position: absolute;
      left:405px;;
    }
    
  }
`;
export const ContentWrapper = styled.div`
  font-size: 18px;
  line-height: 49px;
  width: 88%;
  margin-bottom: 23px;

  @media all and (min-width: 1366px) {
    width: ${({ type }) => (type === "Full" ? "90%" : "554px")};
    height: ${({ type }) => (type === "Full" ? "" : "80px")};
    margin-left: ${({ type }) => (type === "Full" ? "auto" : "")};
  }
  &#brandtitle {
    @media all and (min-width: 1366px) {
      margin-left: 80px;
    }
  }
  &#exptitle {
    @media all and (min-width: 1366px) {
      margin-left: 130px;
    }
  }
  &#launchtitle {
    @media all and (min-width: 1366px) {
      width: 400px;
      height: 100px;
      margin-left: -150px;
    }
  }
`;

export const ContentText = styled.h1`
  font-weight: 100 !important;
`;

export const ContentMainTextWrapper = styled.div`
  width: 88%;
  font-size: 18.0233px;
  line-height: 25px;
  color: #000000;
  @media all and (min-width: 1366px) {
    width: ${({ type }) => (type === "Full" ? "" : "554px")};
  }
  &#brandtext {
    @media all and (min-width: 1366px) {
      margin-left: 80px;
    }
  }
  &#brandexp {
    @media all and (min-width: 1366px) {
      margin-left: 140px;
    }
  }
`;

export const ContentMainText = styled.p`
  font-weight: 400;
  text-align: left;
  font-family: Noto Sans;
  margin-bottom: 20px;
  @media all and (min-width: 1366px) {
    width: ${({ type }) => (type === "Full" ? "90%" : "70%")};
    text-align: ${({ type }) => (type === "Full" ? "" : "justify")};
    font-size: ${({ type }) => (type === "Full" ? "18px" : "18px")};
    margin: ${({ type }) => (type === "Full" ? "auto" : "")};
    line-height: ${({ type }) => (type === "Full" ? "24px" : "24px")};
  }
`;

export const ContentSubDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 268px;

  margin-bottom: 56px;
  box-shadow: ${({ dropShadow }) =>
    dropShadow ? "7.13624px 7.13624px 18.664px rgba(0, 0, 0, 0.129)" : "none"};
  @media all and (min-width: 1366px) {
    position: ${({ type }) => (type === "Full" ? "absolute" : "absolute")};
    left: ${({ type }) => (type === "Full" ? "580px" : "500px")};
    width: ${({ type }) => (type === "Full" ? "" : "400px")};
    height: ${({ type }) => (type === "Full" ? "" : "360px")};
    top: ${({ type }) => (type === "Full" ? "" : "80px")};
    background: ${({ type }) => (type === "Full" ? "" : "#ffffff")};
    display: ${({ subtextfalse }) => subtextfalse ? 'none': 'flex'};
    z-index: 30;
  }
`;

export const ContentSubText = styled.p`
  text-align: center;
  width: 80%;
  margin: auto;

  font-weight: 400px;
  line-height: 24px;
  font-size: 20px;
  @media all and (min-width: 1366px) {
    font-size: ${({ type }) => (type === "Full" ? "25px" : "22px")};
    width: ${({ type }) => (type === "Full" ? "70%" : "70%")};
    line-height: ${({ type }) => (type === "Full" ? "33px" : "33px")};
    text-align: ${({ type }) => (type === "Full" ? "" : "left")};
  }
`;

export const DetailButton = styled(Link)`
  text-decoration: none;
  color: #3a7a44;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
`;
export const BtnText = styled.p`
  margin-right: 4px;
`;

