import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
  Wrapper,
  Container,
  TextContent,
  DetailButton,
  BtnText,
  TextContent1,
} from "./soonstyle";
const ComingSoon = () => {
  return (
    <Wrapper>
      <Container>
        <TextContent>Preparing something </TextContent>
        <TextContent1>Coming soon.. </TextContent1>

        <DetailButton to='/contact'>
          <BtnText>CONTACT SUPPORT</BtnText>
          <AiOutlineArrowRight />
        </DetailButton>
      </Container>
    </Wrapper>
  );
};

export default ComingSoon;
